/*----------------------------------------------------
@File: Default Styles
@Author: Dreemsthemes
@URL: http://wethemez.com

This file contains the styling for the actual theme, this
is the file you need to edit to change the look of the
theme.
---------------------------------------------------- */
/*=====================================================================
@Template Name: Cryptogold
@Author: Dreemsthemes
@Developed By: Rony
@Developer URL: http://rocky.obaidul.com

@Default Styles

Table of Content:
01/ Variables
02/ predefin
03/ header
04/ slider
05/ photography
06/ button 
07/ work 
08/ team
09/ contact 
10/ price 
11/ portfolio 
12/ blog 
13/ sidebar  
13/ page-header  
13/ project  
13/ we-dope  
13/ progress
13/ comingsoon
13/ error
13/ footer
=====================================================================*/
/*----------------------------------------------------*/
/*font Variables*/
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700|Open+Sans|Lora:400i");
@font-face {
  font-family: Anaktoria;
  src: url("../fonts/Anaktoria.otf") format("opentype");
}
@font-face {font-family:"Lato Light";src:url("../fonts/Lato-Light.eot?") format("eot"),url("../fonts/Lato-Light.woff") format("woff"),url("../fonts/Lato-Light.ttf") format("truetype"),url("../fonts/Lato-Light.svg#Lato-Light") format("svg");font-weight:normal;font-style:normal;}

.col-md-offset-right-1 {
  margin-right: 8.33333333%;
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
.list_style {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.slideUp {
  margin-top: -100px;
}
a {
  text-decoration: none;
}

a:hover, a:focus {
  text-decoration: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.row.m0 {
  padding: 0px;
  margin: 0px;
}

body {
  line-height: 30px;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  color: #797979;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat", sans-serif;
}

h1{
  font-family: "Antic", "Adobe Blank";
  margin: 0;
}
h5,h6{
  font-family: "Lato Light", "Adobe Blank";
}
h2{
  font-family: "Antic", "Adobe Blank";
  /* font-weight: 400; */
  font-style: normal;
  letter-spacing: 1px;
  /* font-weight: bold;*/
  text-transform: uppercase;
 
  color: #787a7d;
  font-size: 2rem;
}
.mt_40 {
  margin-top: 40px;
}

.mt_70 {
  margin-top: 70px;
}

.mt_80 {
  margin-top: 80px;
}

.mb_70 {
  margin-bottom: 70px;
}

.mb_60 {
  margin-bottom: 60px;
}

.mb_80 {
  margin-bottom: 80px;
}

.t_color {
  color: #787a7d;
}

@media (max-width: 767px) {
  .container {
    max-width: 100%;
  }
}

.row_reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.bottom_line {
  width: 80px;
  height: 2px;
  margin: 25px auto 0px;
  display: inline-block;
  background: #bcbcbc;
}

.title_h2 {
  font-size: 35px;
  line-height: 45px;
  font-weight: 700;
}

.title_p {
  font-size:16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 0px;
  max-width: 570px;
  margin-left: auto;
  margin-right: auto;
}

/* Main title css
============================================================================================ */
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*============ theme_btn css =========*/
.theme_btn {
  font-size: 20px;
  font-weight: 500;
  color: #040c2c;
  font-family: "Montserrat", sans-serif;
  border: 2px solid #040c2c;
  padding: 13px 36px;
  border-radius: 3px;
  position: relative;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  z-index: 1;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  cursor: pointer;
  min-width: 140px;
  text-align: center;
}

.theme_btn:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  border-radius: 1px;
  background: #040c2c;
  -webkit-transform-origin: 50%;
  -ms-transform-origin: 50%;
  transform-origin: 50%;
  transition-property: -webkit-transform;
  -webkit-transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.theme_btn:hover {
  color: #fff;
}

.theme_btn:hover:before {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*========= header_area css ===========*/
 .navbar-collapse {

  
  padding-left: 19%;
  padding-right: 10%;
 }
 .navbar {
  width: 100%;
  /* top: 0px; */
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  /* position: sticky; */
  z-index: 1001;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-backface-visibilite: hidden;
  padding: 0;
  
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
} 

@media (min-width: 1300px) {
  .navbar .custome_container {
    max-width: 1440px;
  }
}
@media (max-width: 992px){
  .logo {
    display: none;
  }
}
@media (min-width: 992px){
  .logo_navbar {
    opacity: 1 !important;
  }
}
.navbar .logo_h {
  margin-right: 0px;
}

.navbar .logo_h img {
  max-width: 100%;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.navbar .logo_h img + img {
  display: none;
}

/* .navbar .navbar-nav {
  margin: auto;
} */
.navbar .navbar-nav .nav-item {
  margin-left: 1vw;
}

.nav-item .nav-link {
  font: 800 14px/30px "Lato", sans-serif;
  color: #787a7d;
  text-transform: uppercase;
  position: relative;
  padding: 0px;
  cursor: pointer;
  letter-spacing: 2px;
}

.nav-item .nav-link:before {
  content: "";
  background: #787a7d;
  opacity: 0.71;
  position: absolute;
  left: auto;
  right: 0px;
  bottom: 0px;
  width: 0%;
  height: 1.3px;
  z-index: 1;
  -webkit-transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
  -o-transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
  transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
}

.nav-item .nav-link.active:before, .nav-item .nav-link:hover:before {
  width: 100%;
  left: 0;
}

.navbar .navbar-nav.nav-right .nav-item {
  margin-left: 0px;
}

.navbar .login {
  padding: 5px 34px;
  font: 600 14px/30px "Montserrat", sans-serif;
  background: #fff;
  border-radius: 3px;
  color: #494949;
  text-transform: uppercase;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  position: relative;
  display: inline-block;
  border: 2px solid transparent;
  overflow: hidden;
  z-index: 1;
  position: relative;
  margin-top: 7px;
}

.navbar .login:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  border-radius: 0px;
  background: #fff;;
  -webkit-transform-origin: 50%;
  -ms-transform-origin: 50%;
  transform-origin: 50%;
  transition-property: -webkit-transform;
  -webkit-transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.navbar .login:hover {
  -webkit-box-shadow: 0px 16px 30px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 16px 30px 0px rgba(0, 0, 0, 0.12);
  color: #040c2c;
}

.navbar .login:hover:before {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}
.login-container{
  display: flex;
  /* align-items: center; */
  justify-content: center;
  /* height: 100vh;*/
}

.login-form{
  width: 400px;
  background: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cadastro-form{
  width: 900px;
}
/* .cadastro-form input{
  padding-right: 800px;
} */
.login-form  img {
    width: 500px;
    margin: 10px 0 40px;
  }
  .login-form  p {
    color: #ff3333;
    margin-bottom: 15px;
    border: 1px solid #ff3333;
    padding: 10px;
    width: 100%;
    text-align: center;
  }
.login-form input,.input-text {
  flex: 1 1;
  /* height: 46px; */
  margin-top: 15px;
  padding: 0 20px;
  color: #777;
  /* font-size: 15px; */
  width: 100%;
  border: 2px solid #1c3d68;
  }
  .login-form input::placeholder,.input-text::placeholder {
    color: #999;
  }
  .login-form  button,.input-button {
    color: #fff;
    font-size: 16px;
    background: #1c3d68;
    height: 40px;
    border: 0;
    border-radius: 0;
    width: 100%;
  }
  .login-form hr {
    margin: 20px 0;
    border: none;
    border-bottom: 1px solid #cdcdcd;
    width: 100%;
  }
  .login-form a {
    font-size: 16;
    font-weight: bold;
    color: #999;
    text-decoration: none;
  }
.cadastro-form .ppimg:hover {
  opacity: 50%;
  content: "X";
}
.navbar_fixed .navbar{
  background: #fff;
  /* -webkit-box-shadow: 0px 2px 35px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 35px 0px rgba(0, 0, 0, 0.1); */
  -webkit-box-shadow: 0px 15px 20px -15px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 15px 20px -15px rgba(0, 0, 0, 0.1);
  /* top: 0; */
}
.transition-default{
  transition: all 500ms cubic-bezier(0.55, 0, 0.1, 1);
}
.navbar_fixed .logo_h img {
  display: none;
}

.navbar_fixed .logo_h img + img {
  display: inline-block;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.navbar_fixed .navbar .navbar-nav .nav-item .nav-link {
  color: #787a7d;
}

.navbar_fixed .navbar-nav .nav-item .nav-link:before {
  background: #787a7d;
}

.navbar_fixed .login {
  border-color: #787a7d;
  color: #787a7d;
}
.navbar_fixed .login:before{
  background: #787a7d;
}

.navbar_fixed .login:hover {
  color: #787a7d;
}
.navbar-toggler {
  border: 0px;
  padding: 0px;
  border-radius: 0px;
  height: 90px;
  cursor: pointer;
}
.navbar-collapse {
  flex-grow: 0;
  
  /* margin-inline-end: 17%; */
}
@media (max-width: 992px){
  .navbar-expand-lg .navbar-toggler {
      margin-left: auto;
      
  }
}
@media (min-width: 992px){
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
/* @media (min-width: 992px){
  .navbar-expand-lg .navbar-toggler {
      opacity: 0;
      display: block;
  }
} */
/*
 .navbar-toggler span {
  display: block;
  width: 30px;
  height: 2px;
  background-image: -moz-linear-gradient(0deg, #ed3d82 0%, #f66264 75%, #fe8645 100%);
  background-image: -webkit-linear-gradient(0deg, #ed3d82 0%, #f66264 75%, #fe8645 100%);
  background-image: -ms-linear-gradient(0deg, #ed3d82 0%, #f66264 75%, #fe8645 100%);
  margin: auto;
  margin-bottom: 5px;
  -webkit-transition: all 400ms linear;
  -o-transition: all 400ms linear;
  transition: all 400ms linear;
} 
.navbar-toggler[aria-expanded="true"] span:first-child {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  position: relative;
  top: 6px;
}

.navbar-toggler[aria-expanded="true"] span:nth-child(2) {
  opacity: 0;
}

.navbar-toggler[aria-expanded="true"] span:nth-child(3) {
  -webkit-transform: rotate(42deg);
  -ms-transform: rotate(42deg);
  transform: rotate(42deg);
  bottom: 8px;
  position: relative;
}

.navbar-toggler:focus {
  outline: none;
} */
/* 
.dark_menu .navbar-nav .nav-item .nav-link{
  color: #040c2c;
}
.dark_menu .navbar-nav .nav-item .nav-link.active:before, .dark_menu .navbar .navbar-nav .nav-item .nav-link:hover:before{
  background: #040c2c;
}
.dark_menu .login{
  background: #040c2c;
  color: #fff;
}
.dark_menu .logo_h img{
  display: none;
}
.dark_menu .logo_h img + img{
  display: block;
}
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*========== banner_area css =========*/
.banner_area,.banner_area_two {
  min-height: 100vh;
  position: relative;
  z-index: 1;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
}
.react-parallax-bgimage{
  height: 100vh;
  top: 0;
  z-index: -1;
  width: 100%;
  left: 0;
}
.react-parallax-content{
  width: 100%;
}
.banner_area:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /*background: url("../../image/grid-70.png") no-repeat scroll center 0/cover;*/
  z-index: 0;
}
.particle{
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.banner_content {
  color: #fff;
  position: relative;
}

.banner_content h5 {
  font-size: 20px;
  margin: 0;
  font-weight: 400;
  margin-bottom: 32px;
  letter-spacing: 1px;
}

.banner_content h2 {
  margin: 10px 0px;
  font-size: 55px;
  font-weight: 700;
}

.banner_content h4 {
  font-size: 22px;
  letter-spacing: 0px;
  padding: 10px 0px 30px;
}
.banner_content h4 span{
  font-weight: 700;
}

.banner_content .social_icon li {
  display: inline-block;
  margin: 0px 3px;
}

.banner_content .social_icon li a {
  font-size: 14px;
  width: 42px;
  height: 42px;
  line-height: 43px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.3);
  display: inline-block;
  color: #fff;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.banner_content .social_icon li a:hover {
  background: #fff;
  color: #040c2c;
}

.banner_area_two .banner_content{
  color: #040c2c;
  position: relative;
  z-index: 3;
}
.banner_area_two .banner_content h2{
  font-size: 75px;
}
.banner_area_two .banner_content h3{
  font-size: 24px;
  padding-top: 20px;
  margin-bottom: 2px;
}
.banner_area_two .banner_content .social_icon li a{
  background: #040c2c;
}
.banner_area_two .banner_content .social_icon li a:hover{
  box-shadow: 0px 2px 35px 0px rgba(4, 12, 44, 0.5);
  color: #fff;
  transform: translateY(-4px);
}
.banner_area_two .one_img{
  position: absolute;
  top: -15px;
  left: -150px;
  opacity: 0.6;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite
}
@-webkit-keyframes spin {
  0% {
      -webkit-transform: rotate(0);
      transform: rotate(0)
  }

  100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg)
  }
}

@keyframes spin {
  0% {
      -webkit-transform: rotate(0);
      transform: rotate(0)
  }

  100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg)
  }
}
a.logout-btn {
  padding: 0.25em 1em;
  border-style: double;
  border-color: #1c3d68;
  font: 600 14px/30px "Lato Light", sans-serif;
  color: #787a7d;
  text-transform: uppercase;
  cursor: pointer;
  letter-spacing: 2px;
  margin-right: 1rem;
}
/*========== banner_area css =========*/
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*=========== about_area css ===========*/
.about_area {
  /* overflow: hidden; */ 
  padding-top: 20px;
  background-color: white;
  text-align: left;
}

@media (max-width: 992px) {
  .logo-header{
    opacity: 1 !important;
    margin-left: 0 !important;
  }
  .logo-redes {
    text-align: center !important; 
    flex-direction: column;
  }
  .logo{
    margin-bottom: 2rem !important;
  }
}
.saveButton{
    display: inline-grid;
    height: 50px;
    background: white;
    border-style: solid;
    border-width: 2px;
    border-top: 0;
    border-radius: 0 0 20% 20%;
    font: 600 14px/30px "Lato Light", sans-serif;
    color: #787a7d;
    text-transform: uppercase;
    cursor: pointer;
    letter-spacing: 2px;
  }
.logo-header img{
  padding: 1rem;
  height: 100px;
  width: 100%;
  object-fit: contain;
}
.logo-header h1{
  font-size: xx-large;
  letter-spacing: 1px;
}
.logo-header h2{
  font-size: medium;
  letter-spacing: 1px;
}
.logo-redes {
  display: flex;
  justify-content: space-around;
  margin: 1.5rem;
  margin-bottom: 0rem;
  text-align: left;
}
.logo{
  margin-bottom: 0;
  transition: all 500ms cubic-bezier(0.55, 0, 0.1, 1);
  display: inline-block;
}
.logo img{
  width: 350px;
}
.logo h2{
  font-size: 1.5rem;
  letter-spacing: 1px;
}
.preview-more{
  min-width: fit-content;
}
.about_quotes{
  padding: 33px;
  background-color: #ffffffeb;
}
.about_quotes p{
  font-size: 18px;
  /* font-style: italic; */
  line-height: 36px;
  font-family: Lato;
  color: #787a7d;
  text-align: justify;
  margin-left: 2rem;
}
.about_quotes_quote1{
  width: 60px;
  margin-bottom: 1rem;
}
.about_quotes_quote2{
  width: 60px;
  float: right;

}
@media (max-width: 470px) {
  .about_col {
    z-index: 1;
    left: 0%;
  }
}
#responsive-navbar-nav:not(.show){
  height: 100px;
}
@media (min-width: 470px) {
  .about_col {
    z-index: 1;
    left: 10%;
  }
  .about_col2 {
    z-index: 1;
    right: 10%;
  }
}

@media (max-width: 1200px) {

  .about_col2 {
    z-index: 1;
    right: 0%;
    min-width: auto;
    max-width: 80%;
  }
}
@media (min-width: 992px) {
  .about_col {
    z-index: 1;
    left: 25%;
  }
}
.about_content {
  padding-right: 88px;
  padding-bottom: 50px;
  padding: 20px;
  background-color: white; 
  font-family: "Antic", "Adobe Blank";
  color:#787a7d;
  min-width: 370px;
  max-width: 370px;
}

.about_content h2,.about_content a {
  font-size: 18px;
  letter-spacing: 1px;
  color:#787a7d;
}

.about_content h6 {
  color: #999;
  letter-spacing: 1px;
  margin-bottom: 30px;
  font-size: 16px;
}

.about_content p {
  padding-bottom: 20px;
  font-size: xx-large;
  line-height: 3rem;

}
.about_content .theme_btn.active{
  color: #fff;
}
.about_content .theme_btn.active:before{
  transform: scale(1);
}
.about_content .theme_btn + .theme_btn {
  margin-left: 15px;
}
.about_content .theme_btn.active:hover{
  color: #040c2c;
}
.about_content .theme_btn.active:hover:before{
  transform: scaleX(0);
}
.about_img img {
  margin-left: -50px;
}
.posts_area {
  /* overflow: hidden; */
  background-color: white;
  text-align: left;
}
.posts-preview{
  width: 100%;
  padding: 4rem 0;
  text-align: center;
}

@media (max-width: 1200px) {
  .posts-preview{
    padding-left: 0;
    padding-right: 0;
  }
}
.sobre-conteudo{
  width: 100%;
  padding: 8vw;
  padding-top: 4vw;
  text-align: justify;
}
.sobre-conteudo p{
  font-family: Lato;
  color:#787a7d;
  text-align: justify;
}
.sobre-conteudo h2{
  margin-bottom: 3rem;
  text-transform: capitalize;
  color:#787a7d;
}
.sobre-conteudo h2::before{
  content: "";
  margin-bottom: 1rem;
  display: block;
  width: 30%;
  height: 5px;
  background-color: #1c3d68;
}
.sobre-video{
  margin:auto;
  width: 100%;
}
.sobre-video iframe{
  max-width: 100%;
}
.mentoring{
  text-align: center;
  align-items: center;
  margin-bottom: 1rem;
}
.img-iceberg{
  object-fit: cover;
  height: 100%;
  width: inherit;
}
.img-sobre{
  margin: auto;
}
.blog-preview h2,.news-preview h2{
  text-transform: inherit;
  font-size: 3em;
}
.news-preview h2{
  color:white
}
.news-preview{
  color: white;
} 
.preview-list{
  padding: 0;
} 
.preview-item{
  display: flex;
  /*justify-content: space-between;    */
  align-items: center;
} 
.preview-capa{
  margin-left: 1rem;
  width: 80px;
  height: 42px;
  object-fit: cover;
} 
/* .preview-item::after:not(:last-child){
  width: 91%;
  height: 2px;
  overflow: hidden;
  background-color: #e5e5e5;
  border-bottom: 1px solid #ffffff;
  margin: auto;
}  */
.divider{
  width: 110%;
  height: 1px;
  overflow: hidden;
  background-color: #787a7d3b;
  /* border-bottom: 2px solid #ff0000; */
  margin: auto;
}
.divider-white{
  background-color: rgba(255, 255, 255, 0.582);
}
.preview-item h6 {
  padding: 0.5rem;
}
.preview-item h5 {
  padding: 0.5rem;
}
.preview-title {
  width: 100%;
  text-align: right;
}
.bg-1{
  background-color: #536680;
}

.bg-4{
  background-color: #1c3d68;
}
.footer-copyright{
  background-color: #1c3d68;
  color: #f8f7fc;
  padding: 0.5rem;
}
.footer-copyright small{
  font-family: Lato Light;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1px;
}
.footer2_area h2{
  font-family: Lato Light;
  color: #f8f7fc;
  font-size: small;
  margin-bottom: 15px;
}
.footer2_area p{
  color: #f8f7fc;
  line-height: 23px;
  font-family: Lato Light;
  letter-spacing: 1px;
}
.footer2_area .col-lg-3,.footer2_area .col-6{
  padding: 3rem;
  padding-bottom: 3rem;
  text-align: left;
}
.work_area {
  background: #fafafa;
  padding: 110px 0px 90px;
}

.work_item {
  text-align: center;
  border: 1px solid #f3f1f1;
  background: #fff;
  padding: 40px 25px 30px;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  cursor: pointer;
}

.work_item i {
  border: 1px solid #8a8a8a;
  border-radius: 50%;
  color: #8a8a8a;
  font-size: 32px;
  height: 80px;
  line-height: 80px;
  margin-bottom: 20px;
  width: 80px;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  display: inline-block;
}

.work_item h2 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  padding-bottom: 5px;
}

.work_item:hover {
  -webkit-box-shadow: 0px 5px 35px 0px rgba(148, 146, 245, 0.15);
  box-shadow: 0px 5px 35px 0px rgba(148, 146, 245, 0.15);
}

/*================= depoimentos_area css ==============*/
.carousel-control-prev-icon{
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%231c3d68' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}
.carousel-control-next-icon{
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%231c3d68' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}
#depoimentos section{
  padding-top: 100px;
  padding-bottom: 100px;
}
#fade-quote-carousel.carousel {
  padding-bottom: 1px;
}

#fade-quote-carousel.carousel .carousel-inner{
  max-width: 700px;
  margin: 50px auto;
}
#fade-quote-carousel.carousel .carousel-inner h3{
  color: #1c3d68;
  margin: 0.5em;
}
.carousel-inner .carousel-item {
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
  position: absolute;
  transition: opacity 1s ease-in-out;
}
.carousel-inner .carousel-item:first-of-type {
  position: relative;
}
.carousel-inner .active {
  opacity: 1;
}

/* #fade-quote-carousel.carousel .carousel-inner .carousel-item-next {
  opacity: 0 !important;
}
#fade-quote-carousel.carousel .carousel-inner .carousel-item-prev {
  opacity: 0 !important;
} */
#fade-quote-carousel.carousel .carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  z-index: 15;
  width: 60%;
  padding-left: 0;
  margin-left: -30%;
  text-align: center;
  list-style: none;
  bottom: 10px;
  align-items: center;
}
#fade-quote-carousel.carousel .carousel-indicators > li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 4px;
  text-indent: -999px;
  cursor: pointer;
  border-radius: 10px;
  background-color: #1c3d68;
  border: none;
}
#fade-quote-carousel.carousel .carousel-indicators .active {
  width: 12px;
  height: 12px;
  margin: 4px;
}
#fade-quote-carousel blockquote {
    text-align: center;
    border: none;
}
#fade-quote-carousel .profile-circle {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    border-radius: 100px;
    background-color: rgba(0,0,0,.2);
    object-fit: cover;

}
/*================= sc-chat css ==============*/
.sc-launcher {
  z-index: 999999;
}
.sc-chat-window {
  z-index: 999999;
}
.sc-user-input--button:last-child{
  display: none;
}
.sc-header--img{
  height: 54px;
  width: 54px;
  object-fit: contain;
}
.sc-user-input--text:empty:before {
  content: "Envie uma mensagem...";
}
/*================= about_area_two css ==============*/
.about_area_two {
  padding: 120px 0px;
}

.n_section_title {
  margin-bottom: 60px;
}

.n_section_title .top_border {
  height: 40px;
  width: 1px;
  background: #cccccc;
  margin: 0 auto;
  display: block;
}

.n_section_title h6 {
  font-size: 14px;
  color: #ff214f;
  margin: 30px 0px 10px;
}

.n_section_title h2 {
  font: 300 32px/40px "Montserrat", sans-serif;
  color: #232323;
}

.n_section_title h2 span {
  font-weight: 500;
}

.about_item {
  padding-left: 55px;
  position: relative;
}

.about_item i {
  font-size: 26px;
  position: absolute;
  left: 0;
  top: 5px;
}

.about_item h3 {
  font: 600 14px/24px "Montserrat", sans-serif;
  text-transform: uppercase;
  color: #040c2c;
}

.about_item p {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 0px;
}

.promo_section .padding {
  padding: 0px;
}

.promo_section .promo_box_one {
  background: #000;
  padding: 0px 60px;
}

.promo_section .promo_box_one .content {
  position: relative;
}

.promo_section .promo_box_one .content i {
  font-size: 80px;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  color: #fff;
  position: absolute;
  top: 0;
  left: -18px;
  color: #ff214f;
}

.promo_section .promo_box_one .content p {
  font: 600 28px/36px "Montserrat", sans-serif;
  color: #fff;
  padding-top: 80px;
  margin-bottom: 0px;
}

.promo_section .promo_video {
  position: relative;
  z-index: 1;
  text-align: center;
}

.promo_section .promo_video:before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 0;
}

.promo_section .promo_video img {
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.promo_section .promo_video .video_btn {
  position: absolute;
  top: 50%;
  font-size: 22px;
  line-height: normal;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #fff;
  left: 50%;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.promo_section .promo_video .video_btn i {
  padding-right: 10px;
}

.promo_section .promo_skill {
  background: #f7f7f7;
  padding: 40px 60px;
}

.promo_section .promo_skill h3 {
  font-weight: 600;
  font-size: 28px;
  line-height: normal;
  color: #040c2c;
  margin-bottom: 25px;
}

.promo_section .promo_skill .progress_bar_info {
  width: 100%;
}

.promo_section .promo_skill .progress_bar_info .progress {
  height: 8px;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #d0dae3;
  overflow: visible;
  margin-bottom: 20px;
  position: relative;
}

.promo_section .promo_skill .progress_bar_info .progress .progress-bar {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: #F24259;
  width: 10%;
  border-radius: 10px;
  -webkit-transition: all 2s;
  -o-transition: all 2s;
  transition: all 2s;
}

.promo_section .promo_skill .progress_bar_info .progress .count-tip {
  position: absolute;
  top: -46px;
  right: 0;
  color: #797979;
  font-size: 14px;
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*=========== portfolio_area css ============*/
.portfolio_area {
  padding: 110px 0px 90px;
}

.portfolio_menu {
  margin-bottom: 50px;
}

.portfolio_menu li {
  display: inline-block;
  background-color: transparent;
  border: 1px solid #ededed;
  border-radius: 2px;
  color: #494949;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  line-height: 38px;
  margin-right: 18px;
  padding: 0 25px;
  text-transform: capitalize;
  -webkit-transition: background 0.5s ease-in-out, color 0.2s linear;
  -o-transition: background 0.5s ease-in-out, color 0.2s linear;
  transition: background 0.5s ease-in-out, color 0.2s linear;
  cursor: pointer;
}

.portfolio_menu li:hover, .portfolio_menu li.active {
  background: #040c2c;
  color: #fff;
}

.grid-item {
  margin-bottom: 30px;
}

.portfolio {
  position: relative;
}

.portfolio img {
  max-width: 100%;
}

.portfolio .item-img-overlay {
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: 8px;
  background: rgba(255, 255, 255, 0.95);
  opacity: 0;
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
  z-index: 2;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  cursor: pointer;
}

.portfolio .item-img-overlay .overlay-info {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
}

.portfolio .item-img-overlay .overlay-info h6 {
  font-size: 14px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 2px;
  position: relative;
  margin-bottom: 15px;
  -webkit-transform: translateY(-15px);
  -ms-transform: translateY(-15px);
  transform: translateY(-15px);
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}

.portfolio .item-img-overlay .overlay-info .icons a {
  display: inline-block;
  width: 45px;
  height: 45px;
  font-size: 16px;
  line-height: 43px;
  border-radius: 50%;
  border: 1px solid #111;
  background: #111;
  color: #eee;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  margin: 0px 5px;
}

.portfolio .item-img-overlay .overlay-info .icons a:hover {
  background: #fff;
  color: #040c2c;
}

.portfolio:hover .item-img-overlay {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.portfolio:hover .overlay-info h6 {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

/*================== project_count_area css ================*/
.project_count_area {
  background: #fafafa;
  padding: 120px 0px;
}

.project_count_area .counter_item i {
  font-size: 35px;
  margin-bottom: 25px;
}

.project_count_area .counter_item .t_color {
  font: 600 35px/40px "Montserrat", sans-serif;
}

.project_count_area .counter_item p {
  margin-bottom: 0px;
  font-size: 16px;
}

.p_count_two {
  background: #fff;
  padding: 80px 0px;
}

.p_count_two .counter_item i {
  color: #ff214f;
}

.bg_two {
  background: #fafafa;
}

/*=============== testimonial_area css  ==============*/
.testimonial_area {
  background: url("/image/portfolio/img-3.jpg") no-repeat scroll center 0/cover;
  position: relative;
  z-index: 1;
  padding: 120px 0px;
}

.testimonial_area:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 0;
  width: 100%;
  height: 100%;
}

.testimonial_area .testimonial_slider {
  text-align: center;
  padding: 0px 100px;
}

.testimonial_area .testimonial_slider .item {
  color: #fff;
}

.testimonial_area .testimonial_slider .item .author_img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
  border: 1px solid #ededed;
  background: #fff;
}

.testimonial_area .testimonial_slider .item .author_img img {
  max-width: 100%;
  width: auto;
}

.testimonial_area .testimonial_slider .item h6 {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  margin: 15px 0 0px;
}

.testimonial_area .testimonial_slider .item span {
  font: 400 13px "Open Sans", sans-serif;
  opacity: 0.6;
}

.testimonial_area .testimonial_slider .item p {
  margin-bottom: 0px;
  margin-top: 15px;
}
.testimonial_area .testimonial_slider .slick-dots {
  left: 0;
  position: relative;
}
.testimonial_area .testimonial_slider .slick-dots li{
  margin: 0px;
}
.testimonial_area .testimonial_slider .slick-dots li button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  background: #fff;
  opacity: 0.50;
  margin: 0px 0px 0px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.testimonial_area .testimonial_slider .slick-dots li button:before{
  display: none;
}

.testimonial_area .testimonial_slider .slick-dots .slick-active button{
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  opacity: 1;
}

.team_area {
  padding: 120px 0px;
}

.team_area .team_item img {
  max-width: 100%;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.team_area .team_item .content {
  text-align: center;
  padding-top: 25px;
}

.team_area .team_item .content h5 {
  margin-bottom: 0px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: #040c2c;
}

.team_area .team_item .content p {
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 10px;
}

.team_area .team_item .content .social-links {
  border-top: 1px solid #dee2e6;
  padding-top: 5px;
}

.team_area .team_item .content .social-links li {
  display: inline-block;
}

.team_area .team_item .content .social-links li a {
  font-size: 14px;
  color: #939393;
  padding: 0px 3px;
}

.team_area .team_item .content .social-links li a:hover {
  color: #ff214f;
}

.team_area .team_item:hover img {
  -webkit-filter: none;
  filter: none;
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*=========== clients_logo_area css ============*/
.clients_logo_area {
  padding: 120px 0px;
}

.clients_slider {
  text-align: center;
}

.clients_slider .item a {
  display: block;
  text-align: center;
}

.clients_slider .item img {
  max-height: 100px;
  width: auto;
  opacity: 0.7;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  display: inline-block;
}

.clients_slider .item:hover img {
  opacity: 1;
  -webkit-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02);
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*=============== noticias_area css =============*/
/* .noticia-post-header{

} */
.resumo-post {
  position : relative;
  height:200px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.resumo-post:after {
  content  : "";
  position : absolute;
  z-index  : 1;
  bottom   : 0;
  left     : 0;
  pointer-events   : none;
  background-image : linear-gradient(to bottom, 
                    rgba(255,255,255, 0), 
                    rgba(255,255,255, 1) 90%);
  width    : 100%;
  height   : 4em;
}
.noticia-post,.treinamento-post {
  padding: 2em;
}
.noticia-text ,.treinamento-text {
  font-size: .9em;
  color:#666
}
.noticia-title,.treinamento-title {
  text-transform: capitalize;
}
.noticia-title-mini,.treinamento-title-mini {
  font-size: 1.3rem;
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*=============== treinamento_area css =============*/
.container-treinamento{

  margin: 0 1rem;
}
.form-treinamento label{

  font-family: Antic;
  margin-top: 1rem;
}
.editor-wrapper{
  
  box-sizing:border-box;
  height:fit-content;
}
.add-remove-treinamento{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
}

.usuarios-list{
  text-align: left;
} 
.fa.pull-right {
  margin-left: .3em;
}
.pull-right {
  float: right;
}
.treinamento-item{
  display: flex;
  width: 100%;
  align-items: center;
  /* height: 50px; */
  border: 1px solid #dfd7ca;
  padding: 10px;
  margin-bottom: -1px;
} 
.treinamento-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.treinamento-item:first-child {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
h6.treinamento-data {
  text-align: center;
  margin: 0;
  /* color: blueviolet; */
}
.treinamento-item h5 {
  padding: 0.5rem;
}
.treinamento-title {
  text-align: left;
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: "Lato";
  /* font-weight: 400; */
  font-style: normal;
  letter-spacing: 1px;
  /* font-weight: bold;*/
}
.treinamento-categoria {
  text-align: left;
  margin: 0;
  font-family: "Lato Light";
  /* font-weight: 400; */
  font-style: normal;
  letter-spacing: 1px;
  /* font-weight: bold;*/
}
.treinamento-buttons {
  cursor: pointer;
}
.categoria-active {
  background-color: #d6e4ff;
}
@media (min-width: 992px){
  section#treinamento, section#usuario {
      margin: 50px 100px;
  }
}
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*=============== blog_area css =============*/
@media (min-width: 992px) {
  section#blog{
    margin: 50px 100px;
  }
}
@media (max-width: 992px) {
  section#blog{
    margin: 20px 50px;
  }
}
.blog-post-header,.noticia-post-header,.treinamento-post-header {
  display: flex;
  margin-bottom: 10px;
}
.blog-post {
  margin-bottom: 20px;
}
.blog-post p,.noticia-post p {
  text-align: justify;
}
.blog-post a,.noticia-post a {
  text-align: center;
}
.data-post {
  display: inline-block;
  color: white;
  background-color: #1c3d68;
  text-align: center;
  line-height: 1.4;
  padding: 0.4em 1em;
  max-height: 58px;
}
.blog-title,.noticia-title,.treinamento-title{
  font-size: 36px;
  align-self: center;
  margin: 0;
  margin-left: 21px;
  text-transform: none;
  color: #787a7d;
}
.blog-por-user,.noticia-por-user,.treinamento-categoria{
  letter-spacing: 1px;
  font-size: 16px;
}
.blog-profile {
  width: 145px;
  height: 145px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
}

.blog-sobre-profile{
  width: 100%;
  text-align: center;
  margin-top: 20px;
  padding: 0 2vw;
}
.blog-sobre-profile p{
  font-family: Lato;
  color:#787a7d;
  font-size: 14px;
}
.blog-sobre-profile h2{
  margin-top: 4rem;
  color:#787a7d;
  text-transform: none;
  font-size: 1.7rem;
}
.blog-sobre-profile ul li{
  list-style: none;
}
.blog-sobre-profile h2::before{
  content: "";
  margin: 0 auto; 
  margin-bottom: 1rem;
  display: block;
  width: 50%;
  height: 5px;
  background-color: #1c3d68;
}
img.capa-post {
  margin-top: 20px;
  max-height: 600px;
  display: block;
  margin: 25px auto;
}

img.capa-post-mini {
  height: 300px;
}

@media (min-width: 768px){
  .container-blog{
    padding-left: 10%;
  }
}
.container-blog{
  text-align: left;
}
.blog_area {
  background: #fafafa;
  padding: 120px 0px;
}

.bg_w {
  background: #fff;
}

.blog_post {
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.blog_post .blog_img {
  position: relative;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
}

.blog_post .blog_img .post_date {
  position: absolute;
  width: 55px;
  height: 60px;
  font: 700 18px "Montserrat", sans-serif;
  color: #494949;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.95);
  text-align: center;
  top: 20px;
  right: 20px;
  padding-top: 8px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.blog_post .post_content {
  padding: 25px;
  background: #fff;
  border: 1px solid #ebebeb;
  border-top: 0px;
}

.blog_post .post_content .blog-meta span {
  font: 500 15px/15px "Montserrat", sans-serif;
  color: #494949;
  display: inline-block;
  padding-right: 5px;
}

.blog_post .post_content .blog-meta span i {
  padding-right: 5px;
}

.blog_post .post_content .blog-meta span a {
  color: #494949;
}

.blog_post .post_content .blog-meta span + span {
  border-left: 1px solid #494949;
  padding-left: 12px;
}

.blog_post .post_content h2 {
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
  color: #494949;
  padding: 10px 0px 8px;
}
.blog_post .post_content h2:hover{
  color: #040c2c;
}
.blog_post .post_content .read_btn {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #797979;
  -webkit-transition: color 0.3s linear;
  -o-transition: color 0.3s linear;
  transition: color 0.3s linear;
}
.blog_post .post_content .read_btn i{
  vertical-align: middle;
  padding-left: 3px;
  transition: all 0.2s linear;
  display: inline-block;
}
.blog_post .post_content .read_btn:hover {
  color: #040c2c;
}
.blog_post .post_content .read_btn:hover i{
  transform: translateX(5px);
}
.blog_post:hover {
  -webkit-box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.07);
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.07);
}

.blog_post:hover .post_date {
  background: #040c2c;
  color: #fff;
}

/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*============ contato-area css ===============*/

.contato-container {
  max-width: 1400px;

  margin: 1rem auto;
}
.contato-container button{
  height: fit-content;
  padding: 0.5rem 2rem;
}
.contato-redes img {
  max-width: 44px;
  object-fit: scale-down;
}
.contato-container h2{
  text-align: left;
  margin-bottom: 3rem;
  text-transform: none;
  color: #787a7d;
}
.contato-container h2::before{
  content: "";
  margin-bottom: 1rem;
  display: block;
  width: 30%;
  height: 5px;
  background-color: #1c3d68;
}
.contato-container .contato-redes {
  display: inline-grid;
  margin-bottom: 2rem;
  grid-template-columns: min-content min-content;
  grid-row-gap: 17px;
  grid-column-gap: 50px;
  align-items: center;
  justify-items: start;
}
@media (min-width: 768px){
  .contato-container {
    padding: 5rem;
  }
}
.contato-container .contato-redes img {
  height: 50px;
  justify-self: center;
}
/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*============ contact-area css ===============*/
.contact-area {
  padding: 120px 0px;
}

.contact-area h4 {
  font: 600 35px/40px "Montserrat", sans-serif;
  color: #040c2c;
  text-transform: capitalize;
  padding-bottom: 24px;
}

.contact-area .input_form form .form-control {
  font-size: 14px;
  line-break: 24px;
  font-weight: 400;
  color: #494949;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #ededed;
  border-radius: 0px;
  padding-left: 20px;
  text-transform: capitalize;
  min-height: 44px;
  margin-bottom: 30px;
}

.contact-area .input_form form .form-control.placeholder {
  color: #797979;
}

.contact-area .input_form form .form-control:-moz-placeholder {
  color: #797979;
}

.contact-area .input_form form .form-control::-moz-placeholder {
  color: #797979;
}

.contact-area .input_form form .form-control::-webkit-input-placeholder {
  color: #797979;
}

.contact-area .input_form form .form-control.error {
  margin-bottom: 0px;
}

.contact-area .input_form form .form-control.error + .error {
  font: 400 10px/30px "Montserrat", sans-serif;
  color: #494949;
  background: none;
  border: 0px;
  margin-bottom: 0px;
  display: block;
}

.contact-area .input_form form textarea.form-control {
  resize: none;
  height: 142px;
  padding-top: 12px;
}

.contact-area .input_form form .send_btn {
  font-size: 16px;
  border-radius: 4px;
  color: #040c2c;
  padding: 14px 38px 14px 42px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  background: transparent;
}
.contact-area .input_form form .send_btn:hover{
  color: #fff;
}
.contact-area .input_form #success, .contact-area .input_form #error {
  position: absolute;
  top: -15px;
  display: block;
  right: 0;
  left: 0;
  bottom: -15px;
  background: rgba(0, 0, 0, 0.5);
  display: none;
}

.contact-area .input_form #success p, .contact-area .input_form #error p {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: 0;
}

.contact-area .input_form #success {
  color: lawngreen;
}

.contact-area .input_form #error {
  color: orangered;
}

.contact-area .contact_info {
  padding-left: 50px;
}

.contact-area .contact_info ul {
  display: block;
}

.contact-area .contact_info ul .item {
  cursor: pointer;
  display: block;
}

.contact-area .contact_info ul .item .media i {
  color: #494949;
  line-height: 28px;
  text-align: center;
  font-size: 18px;
  padding-right: 10px;
}

.contact-area .contact_info ul .item .media .media-body {
  vertical-align: middle;
}

.contact-area .contact_info ul .item .media .media-body a {
  color: #797979;
  font: 400 16px/28px "Montserrat", sans-serif;
}

.contact-area .contact_info ul .item + .item {
  padding-top: 15px;
}

/*============contact map=============*/
.contact_map {
  position: relative;
  height: 500px;
}


/*---------------------------------------------------- */
/*----------------------------------------------------*/
/*========= Start footer area css ========*/
.footer-area {
  background: #040c2c;
  position: relative;
  padding: 60px 0px 60px;
}

.footer-area .footer-content {
  text-align: center;
}

.footer-area .footer-content .logo {
  display: inline-block;
  margin-bottom: 30px;
}

.footer-area .footer-content .logo img {
  max-width: 100%;
}

.footer-area .footer-content ul li {
  display: inline-block;
  padding: 0px 5px;
}

.footer-area .footer-content ul li a {
  width: 35px;
  height: 35px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 12px;
  line-height: 35px;
  border-radius: 50%;
  display: inline-block;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.footer-area .footer-content ul li a:hover {
  background: #fff;
  color: #494949;
}

.footer-area .footer-content h6 {
  font: 400 14px/20px "Lora", serif;
  color: #999;
  padding-top: 15px;
}

.footer-area .footer-content h6 a {
  color: #fff;
}

.footer-area .footer-content p {
  font: 400 10px/18px "Montserrat", sans-serif;
  color: #fff;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.bg_color{
  background: #fafafa;
}
.work_area_two{
  background: #fff;
  padding: 110px 0px 90px;
}
.bg_w{
  background: #fff;
} 

/*========= End footer area css ========*/
/*---------------------------------------------------- */

/*# sourceMappingURL=style.css.map */